import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
let spv_menus = ['appointments', 'external_chat', 'spv_dashboard', 'goal_management', 'dialer', 'reports', 'sms', 'email', 'system_management', 'operation_management', 'settings', 'wallboard', 'dashboard', 'scheduler', 'alerts', 'quality', 'integrations', 'call_analize']
let agent_menus = ['agent_dashboard', 'crm', 'calendar', 'phone_book', 'phone-book'];

var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Kullanıcı Girişi',
        breadcrumb: [
          {
            text: 'Kullanıcı Girişi',
            active: false,
          },
        ],
      },
    },
    {
      path: '/auth-login',
      name: 'auth-login',
      component: () => import('@/views/Pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Kullanıcı Girişi',
        breadcrumb: [
          {
            text: 'Kullanıcı Girişi',
            active: false,
          },
        ],
      },
    },
    // CRM
    {
      path: '/crm',
      name: 'crm',
      component: () => import('@/views/Agent/Crm/MainCRM.vue'),
      meta: {
        pageTitle: 'CRM',
        breadcrumb: [
          {
            text: 'CRM',
            active: true,
          },
        ],
      },
    },
    {
      path: '/agent_dashboard',
      name: 'agent_dashboard',
      component: () => import('@/views/Agent/Statistics.vue'),
      meta: {
        pageTitle: 'Pano',
        breadcrumb: [
          {
            text: 'Pano',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/apps-chat',
    //   name: 'apps-chat',
    //   component: () => import('@/views/apps/chat/Chat.vue'),
    //   meta: {
    //     pageTitle: 'Yazılı Kanal',
    //     breadcrumb: [
    //       {
    //         text: 'Yazılı Kanal',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/apps/calendar/Calendar.vue'),
      meta: {
        pageTitle: 'Ajanda',
        breadcrumb: [
          {
            text: 'Ajanda',
            active: true,
          },
        ],
      },
    },

    {
      path: '/phone_book',
      name: 'phone_book',
      component: () => import('@/views/Agent/PhoneBook/phone-list/PhoneBooksList.vue'),
      meta: {
        pageTitle: 'Rehber',
        breadcrumb: [
          {
            text: 'Rehber',
            active: true,
          },
        ],
      },
    },
    {
      path: '/evaluated_records',
      name: 'evaluated_records',
      component: () => import('@/views/Agent/Profile/EvaluatedRecords.vue'),
      meta: {
        pageTitle: 'Rehber',
        breadcrumb: [
          {
            text: 'Rehber',
            active: true,
          },
        ],
      },
    },
    {
      path: '/external_chat',
      name: 'external_chat',
      // component: () => import('@/views/apps/chat/Chat.vue'),
      component: () => import('@/views/Agent/ExternalChat/Chat.vue'),
      meta: {
        pageTitle: 'Yazılı Kanal',
        breadcrumb: [
          {
            text: 'Yazılı Kanal',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Agent/Profile/General.vue'),
      meta: {
        pageTitle: 'Profil',
        breadcrumb: [
          {
            text: 'Profil',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/phone-book/edit/:id',
    //   name: 'phone-book-edit',
    //   component: () => import('@/views/Agent/PhoneBook/phone-edit/PhoneBookEdit.vue'),
    // },
    {
      path: '/spv_dashboard',
      name: 'spv_dashboard',
      component: () => import('@/views/Supervisor/Home.vue'),
      meta: {
        pageTitle: 'Canlı İzleme',
        breadcrumb: [
          {
            text: 'Canlı İzleme',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dialer',
      name: 'dialer',
      component: () => import('@/views/Supervisor/Dialer/Main.vue'),
      meta: {
        pageTitle: 'Otomatik Arama',
        breadcrumb: [
          {
            text: 'Otomatik Arama',
            active: true,
          },
        ],
      },
    },
    {
      path: '/appointments',
      name: 'appointments',
      component: () => import('@/views/Supervisor/Appointments/list/List.vue'),
      meta: {
        pageTitle: 'Randevular',
        breadcrumb: [
          {
            text: 'Randevular',
            active: true,
          },
        ],
      },
    },
    {
      path: '/goal_management',
      name: 'goal_management',
      component: () => import('@/views/Supervisor/Goals/Goals.vue'),
      meta: {
        pageTitle: 'Hedef Yönetimi',
        breadcrumb: [
          {
            text: 'Hedef Yönetimi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sms',
      name: 'sms',
      component: () => import('@/views/Supervisor/SMS/Sms.vue'),
      meta: {
        pageTitle: 'SMS',
        breadcrumb: [
          {
            text: 'SMS',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stt_edit',
      name: 'stt_edit',
      component: () => import('@/views/Supervisor/SMS/STT/list/List.vue'),
      meta: {
        pageTitle: 'STT',
        breadcrumb: [
          {
            text: 'STT',
            active: true,
          },
        ],
      },
    },
    {
      path: '/email',
      name: 'email',
      component: () => import('@/views/Supervisor/EMail/EMail.vue'),
      meta: {
        pageTitle: 'EMail',
        breadcrumb: [
          {
            text: 'EMail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/Supervisor/Reports/Main.vue'),
      meta: {
        pageTitle: 'Raporlar',
        breadcrumb: [
          {
            text: 'Raporlar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/quality',
      name: 'quality',
      component: () => import('@/views/Supervisor/Quality/Main.vue'),
      meta: {
        pageTitle: 'Kalite',
        breadcrumb: [
          {
            text: 'Kalite',
            active: true,
          },
        ],
      },
    },
    {
      path: '/scheduler',
      name: 'scheduler',
      component: () => import('@/views/Supervisor/Scheduler/list/List.vue'),
      meta: {
        pageTitle: 'Planlayıcı',
        breadcrumb: [
          {
            text: 'Planlayıcı',
            active: true,
          },
        ],
      },
    },
    {
      path: '/alerts',
      name: 'alerts',
      component: () => import('@/views/Supervisor/Alerts/list/List.vue'),
      meta: {
        pageTitle: 'Uyarılar',
        breadcrumb: [
          {
            text: 'Uyarılar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/wallboard',
      name: 'wallboard',
      component: () => import('@/views/Supervisor/Wallboard.vue'),
      meta: {
        pageTitle: 'Wallboard',
        breadcrumb: [
          {
            text: 'Wallboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Supervisor/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/call_analize',
      name: 'call_analize',
      component: () => import('@/views/Supervisor/CallAnalize.vue'),
      meta: {
        pageTitle: 'CallAnalize',
        breadcrumb: [
          {
            text: 'CallAnalize',
            active: true,
          },
        ],
      },
    },
    // ADMIN
    {
      path: '/operation_management',
      name: 'operation_management',
      component: () => import('@/views/Admin/OperationManagement/Main.vue'),
      meta: {
        pageTitle: 'Operasyon Yönetimi',
        breadcrumb: [
          {
            text: 'Operasyon Yönetimi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/system_management',
      name: 'system_management',
      component: () => import('@/views/Admin/SystemManagement/Main.vue'),
      meta: {
        pageTitle: 'Sistem Yönetimi',
        breadcrumb: [
          {
            text: 'Sistem Yönetimi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Admin/OperationManagement/Users/list/List.vue'),
      meta: {
        pageTitle: 'Kullanıcılar',
        breadcrumb: [
          {
            text: 'Kullanıcılar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/queues',
      name: 'queues',
      component: () => import('@/views/Admin/OperationManagement/Queues/list/List.vue'),
      meta: {
        pageTitle: 'Kuyruklar',
        breadcrumb: [
          {
            text: 'Kuyruklar',
            active: true,
          },
        ],
      },
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('@/views/Admin/OperationManagement/Projects/list/List.vue'),
      meta: {
        pageTitle: 'Projeler',
        breadcrumb: [
          {
            text: 'Projeler',
            active: true,
          },
        ],
      },
    },

    //Settings
    // {
    //   path: '/integrations',
    //   name: 'integrations',
    //   component: () => import('@/views/Admin/Integrations/list/List.vue'),
    //   meta: {
    //     pageTitle: 'Entegrasyonlar',
    //     breadcrumb: [
    //       {
    //         text: 'Entegrasyonlar',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Admin/Settings/Main.vue'),
      meta: {
        pageTitle: 'Ayarlar',
        breadcrumb: [
          {
            text: 'Ayarlar',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import('@/views/Supervisor/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((e) => {
  console.log(e)
  if (spv_menus.includes(e.name)) {
    if (globalThis.LayoutContentRendererDefault && globalThis.AppCustomizer && globalThis.v_navbar) {
      globalThis.LayoutContentRendererDefault.pin_soft_phone = false;
      globalThis.LayoutContentRendererDefault.chat_panel_toggle = false;
      globalThis.LayoutContentRendererDefault.phone_panel_toggle = false;
      // globalThis.AppCustomizer.chat_panel_toggle = false;
      // globalThis.AppCustomizer.phone_panel_toggle = false;
      globalThis.v_navbar.selected_button = '';
    }

  }
  // if (agent_menus.includes(e.name)) {
  //   if (globalThis.LayoutContentRendererDefault && globalThis.AppCustomizer) {
  //     globalThis.LayoutContentRendererDefault.phone_panel_toggle = true;
  //     // globalThis.AppCustomizer.phone_panel_toggle = true;
  //     globalThis.LayoutContentRendererDefault.pin_soft_phone = true;
  //   }

  // }
  // else {
  //   globalThis.LayoutContentRendererDefault.chat_panel_toggle = false;
  //   globalThis.LayoutContentRendererDefault.phone_panel_toggle = false;
  //   globalThis.AppCustomizer.chat_panel_toggle = false;
  //   globalThis.AppCustomizer.phone_panel_toggle = false;
  // }
  // Remove initial loading

  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
